import * as React from 'react'
import * as ReactDOM from 'react-dom/client'

import { t } from '@ulule/localize'
import { COLORS } from '@ulule/owl-kit-components/next'
import { ErrorPage } from '@owl-nest/error'

window.addEventListener('js:locale:loaded', (event) => {
  const container = document.getElementById('react-error-404')
  const root = ReactDOM.createRoot(container)
  root.render(
    <div style={{ position: 'fixed', width: '100%', height: '100%', top: 0, left: 0 }}>
      <ErrorPage
        translations={{
          errorName: t('404 - Page not found'),
          title: t('Are you lost?'),
          subtitle: t("It's ok we all are sometimes"),
          link: t('back to home'),
          searchPlaceholder: t('Search a project'),
        }}
        urls={{
          home: 'https://ulule.com/',
        }}
        color={COLORS.PRIMARY_BLUE}
        search={{
          enable: true,
          action: 'https://ulule.com/discover/',
        }}
        bg={{
          video: {
            mp4: 'https://s3-eu-west-1.amazonaws.com/com.ulule.store/video/owl-404.mp4',
            webm: 'https://s3-eu-west-1.amazonaws.com/com.ulule.store/video/owl-404.webm',
          },
          image: 'https://s3-eu-west-1.amazonaws.com/com.ulule.store/video/owl-loop-mobile.gif',
        }}
      />
    </div>
  )
})
